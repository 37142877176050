import React from 'react';
import alamgirabout from '../../image/alamgirabout.jpg';
import './About.css';

const About = () => {
    return (
        <div style={{ backgroundColor: '#101820FF' }} id="about">
            <div className="container about-us">
                <div>
                    <div className="row">
                        <div className="col-md-6 col-12 about-left-side">
                            <img src={alamgirabout} />
                        </div>
                        <div className="col-md-6 col-12 about-right-side">
                            <h2>About Us</h2>
                            <hr className="hr" />
                            <p className="text-white">hello there, I'm Alamgir talukdar.
                            I have 16+ years experience in three different IT company in different domain like Network infrastructure design, windows and Linux Administration, web hosting and Information & Cyber Security.
                            Have recent experience to face different payment industry standard and global recognized audit like Payment Card Industry Data Security Standard (PCI-DSS), VISA 3D-Security Audit, PIN Security Audit, VISA and Master Card Personalization (Card Bureau) Audit.
                            Working for Security Policy and Procedure writing for Enterprise and payment system.
                            Have hands on experience to working with Public Key Infrastructure (PKI)/ Digital Certificate Solution and Certificate Authority local company.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;