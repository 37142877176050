import React from 'react';
import './Footer.css';
import {
    FaLinkedin,
    FaFacebook,
    FaInstagram,
} from "react-icons/fa";
import alamgirfooterlogo from '../../../image/alamgirlogo.png'

const Footer = () => {
    return (
        <div style={{ backgroundColor: '#101820FF' }}>
            <div className="footer">
                <div className="container">
                    <div className="row pt-4 pb-3">
                        <div className="col-md-4">
                            <div className="icon">
                                <a href="#"><FaLinkedin style={{ fontSize: '20px', }} /></a>
                                <a href="#"><FaFacebook style={{ fontSize: '20px', }} /></a>
                                <a href="#"><FaInstagram style={{ fontSize: '20px', }} /></a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="footerimg" src={alamgirfooterlogo} />
                        </div>
                        <div className="col-md-4">
                            <p className="text-white">copyright © {new Date().getFullYear()} <span style={{ color: '#0BB5DF' }}>Alamgir Talukdar</span> right reserved. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;