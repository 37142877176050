import React from 'react';
import './Contact.css'

const Contact = () => {
    return (
        <div className="contact-us" style={{ backgroundColor: '#101820FF' }} id="contact">
            <div className="container">
                <h2 className="text-center">Contact Us</h2>
                <div className="row mb-2">
                    <div className="col-md-6 col-12 pt-5">
                        <h3 className="text-white pt-3">Contact Info</h3>
                        <p className="text-white">always available for your message!</p>
                        <hr style={{ backgroundColor: '#fff', width: '120px', marginLeft: '0' }} />
                        <div>
                            <h5 className="text-white pt-2">Name</h5>
                            <h5 className="text-white">Alamgir Talukdar</h5>
                            <h5 className="text-white pt-3">Location</h5>
                            <h5 className="text-white">Dhaka, Bangladesh</h5>
                            <h5 className="text-white pt-3">Email</h5>
                            <h5 className="text-white">alamgirde@gmail.com </h5>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 pt-5">
                        <form className="pt-5">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <input className="form-control contact-form text-white" type="text" name="name" placeholder="Name" required />
                                </div>
                                <div className="form-group col-md-6">
                                    <input className="form-control contact-form text-white" type="email" name="email" placeholder="Email" required />
                                </div>
                                <div className="form-group col-12">
                                    <input className="form-control contact-form text-white" type="text" name="subject" placeholder="Subject" required />
                                </div>
                                <div className="form-group col-12">
                                    <textarea className="form-control contact-form text-white" type="message" name="message" placeholder="Message" />
                                </div>
                                <div className="col-12 form-submit">
                                    <button type="submit" className="btn font-weight-bold mt-5 px-5 btn-b">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;