import React from 'react';
import './Nav.css';
import { Link } from 'react-scroll';
import alamgirlogo from '../../../image/alamgirlogo.png'

const Nav = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg py-3 main-nav fixed-top" style={{ backgroundColor: '#101820FF' }}>
                <div className="container">
                    <a className="navbar-brand text-white" to="home"><img src={alamgirlogo} /></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse nav-hover" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto ">
                            <li className="nav-item active">
                                <Link className="nav-link text-white" smooth={true} duration={1000} to="home">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" smooth={true} duration={1000} to="about">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" smooth={true} duration={1000} to="contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Nav;