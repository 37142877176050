import React from 'react';
import About from '../About/About';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Nav from './Nav/Nav';

const Home = () => {
    return (
        <>
           <Nav />
           <Header />
           <About />
           <Contact />
           <Footer />
        </>
    );
};

export default Home;