import React from 'react';
import Typical from 'react-typical';
import alamgir from '../../../image/alamgirpic.png';
import './Header.css'

const Header = () => {
    return (
        <>
            <div style={{ backgroundColor: '#101820FF' }} id="home">
                <div className="container header">
                    <div className="row">
                        <div className="col-md-6 col-12 right-side">
                            <h2 className="pb-3">Alamgir Talukdar</h2>
                            <h3><spab className="pr-2">An</spab>
                                <Typical className="type-text"
                                    steps={['Senior Manager,', 1000, 'Information Security at IT Consultants', 500]}
                                    loop={Infinity}
                                    wrapper="h"
                                />
                            </h3>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="left-side">
                                <img src={alamgir} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;